import React from "react"
import { Landing } from "../components"

const LandingPage = () => {
  return (
    <div>
      <Landing />
    </div>
  )
}

export default LandingPage
